import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { LIST_USERS_WITHOUT_GROUP, LIST_GROUPS } from '../queries/gql';
import { ADD_USER_TO_GROUP } from '../mutations/gql';

function UsersList() {
    // Query to get users without a group
    const { loading: loadingUsersWithoutGroup, error: errorUsersWithoutGroup, data: dataUsersWithoutGroup, refetch: refetchUsersWithoutGroup } = useQuery(LIST_USERS_WITHOUT_GROUP);

    // Query to get all available groups
    const { loading: loadingGroups, error: errorGroups, data: dataGroups } = useQuery(LIST_GROUPS);

    // Mutation to add a user to a group
    const [addUserToGroup] = useMutation(ADD_USER_TO_GROUP, {
        onCompleted: () => {
            refetchUsersWithoutGroup(); // Refetch the list of users without a group after adding
        },
    });

    const [showAddGroupForm, setShowAddGroupForm] = useState({});

    // Handle click to show add group form
    const handleAddToGroup = (userEmail) => {
        setShowAddGroupForm((prevState) => ({ ...prevState, [userEmail]: true }));
    };

    // Handle group selection from the dropdown
    const handleSelectGroup = (userEmail, groupName) => {
        addUserToGroup({ variables: { userEmail, groupName } });
        setShowAddGroupForm((prevState) => ({ ...prevState, [userEmail]: false }));
    };

    if (loadingUsersWithoutGroup) return <p>Loading users without group...</p>;
    if (errorUsersWithoutGroup) return <p>Error loading users: {errorUsersWithoutGroup.message}</p>;

    return (
        <div>
            <h3>Users without group:</h3>
            <ul>
                {dataUsersWithoutGroup && dataUsersWithoutGroup.listUsersWithoutGroup && dataUsersWithoutGroup.listUsersWithoutGroup.length > 0 ? (
                    dataUsersWithoutGroup.listUsersWithoutGroup.map(({ userEmail }) => (
                        <li key={userEmail}>
                            {userEmail}
                            <button onClick={() => handleAddToGroup(userEmail)}>Add to Group</button>
                            {showAddGroupForm[userEmail] && (
                                <div>
                                    {loadingGroups ? (
                                        <p>Loading groups...</p>
                                    ) : errorGroups ? (
                                        <p>Error loading groups: {errorGroups.message}</p>
                                    ) : (
                                        <select onChange={(e) => handleSelectGroup(userEmail, e.target.value)}>
                                            <option value="">Select a group</option>
                                            {dataGroups.listGroups.map(({ groupName }) => (
                                                <option key={groupName} value={groupName}>
                                                    {groupName}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            )}
                        </li>
                    ))
                ) : (
                    <p>No users found.</p>
                )}
            </ul>
        </div>
    );
}

export default UsersList;
