import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import {
    LIST_USERS_OF_GROUP,
    LIST_ALL_GROUPS_AND_THEIR_USERS,
    LIST_ALL_USERS_AND_THEIR_GROUPS,
    FETCH_USERS_NOT_IN_GROUP,
    LIST_USERS_WITHOUT_GROUP,
    LIST_GROUPS_WITHOUT_USER,
} from '../queries/gql';
import {
    REMOVE_USER_FROM_GROUP
} from '../mutations/gql';

function RemoveUsersFromGroup() {
    const { groupName } = useParams();
    const navigate = useNavigate();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const { loading, data, error: queryError } = useQuery(LIST_USERS_OF_GROUP, {
        variables: { groupName },
    });
    const [removeUserFromGroup, { error: mutationError }] = useMutation(REMOVE_USER_FROM_GROUP, {
        refetchQueries: [
            { query: LIST_ALL_GROUPS_AND_THEIR_USERS },
            { query: LIST_ALL_USERS_AND_THEIR_GROUPS },
            { query: LIST_USERS_OF_GROUP, variables: { groupName } },
            { query: FETCH_USERS_NOT_IN_GROUP, variables: { groupName } },
            { query: LIST_USERS_WITHOUT_GROUP },
            { query: LIST_GROUPS_WITHOUT_USER },
        ],
    });

    if (loading) return <p>Loading...</p>;
    if (queryError) return <p>Error: {queryError.message}</p>;

    const handleCheckboxChange = (userEmail) => {
        setSelectedUsers((prev) =>
            prev.includes(userEmail)
                ? prev.filter((email) => email !== userEmail)
                : [...prev, userEmail],
        );
    };

    const handleSubmit = async () => {
        try {
            await Promise.all(
                selectedUsers.map((userEmail) =>
                    removeUserFromGroup({ variables: { userEmail, groupName } }),
                ),
            );
            navigate('/');
        } catch (error) {
            console.error('Error removing users from group:', error);
        }
    };

    return (
        <div>
            <h3>Remove Users from Group: {groupName}</h3>
            {mutationError && <p>Mutation Error: {mutationError.message}</p>}
            <form onSubmit={(e) => e.preventDefault()}>
                {data && data.listUsersOfGroup.map(({ userEmail }) => (
                    <div key={userEmail}>
                        <input
                            type="checkbox"
                            id={userEmail}
                            value={userEmail}
                            onChange={() => handleCheckboxChange(userEmail)}
                        />
                        <label htmlFor={userEmail}>{userEmail}</label>
                    </div>
                ))}
                <button type="button" onClick={handleSubmit}>
                    Remove Selected Users
                </button>
            </form>
        </div>
    );
}

export default RemoveUsersFromGroup;
