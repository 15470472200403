function getEnvVariable(suffix) {
    const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
    const varName = `REACT_APP_${suffix}_${env}`;
    return process.env[varName];
}

const awsExports = {
    'REGION': 'eu-central-1',
    'APP_SYNCH_URL': getEnvVariable('APP_SYNCH_URL'),
    'USER_POOL_ID': getEnvVariable('USER_POOL_ID'),
    'USER_POOL_APP_CLIENT_ID': getEnvVariable('USER_POOL_APP_CLIENT_ID'),
}

export default awsExports;