import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import {
    FETCH_USERS_NOT_IN_GROUP,
    LIST_ALL_GROUPS_AND_THEIR_USERS,
    LIST_ALL_USERS_AND_THEIR_GROUPS,
    LIST_USERS_OF_GROUP,
    LIST_USERS_WITHOUT_GROUP,
    LIST_GROUPS_WITHOUT_USER,
} from '../queries/gql';
import { ADD_USER_TO_GROUP } from '../mutations/gql';


function AddUsersToGroup() {
    const { groupName } = useParams();
    const navigate = useNavigate();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const { loading, error, data } = useQuery(FETCH_USERS_NOT_IN_GROUP, {
        variables: { groupName },
    });
    const [addUserToGroup] = useMutation(ADD_USER_TO_GROUP, {
        refetchQueries: [
            { query: LIST_ALL_GROUPS_AND_THEIR_USERS },
            { query: LIST_ALL_USERS_AND_THEIR_GROUPS },
            { query: LIST_USERS_OF_GROUP, variables: { groupName } },
            { query: FETCH_USERS_NOT_IN_GROUP, variables: { groupName } },
            { query: LIST_USERS_WITHOUT_GROUP },
            { query: LIST_GROUPS_WITHOUT_USER },
        ],
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const handleCheckboxChange = (userEmail) => {
        setSelectedUsers((prev) =>
            prev.includes(userEmail)
                ? prev.filter((email) => email !== userEmail)
                : [...prev, userEmail],
        );
    };

    const handleSubmit = async () => {
        await Promise.all(
            selectedUsers.map((userEmail) =>
                addUserToGroup({ variables: { userEmail, groupName } }),
            ),
        );
        navigate('/');
    };

    return (
        <div>
            <h3>Add Users to Group: {groupName}</h3>
            <form onSubmit={(e) => e.preventDefault()}>
                {data.fetchUsersNotInGroup.map(({ userEmail }) => (
                    <div key={userEmail}>
                        <input
                            type="checkbox"
                            id={userEmail}
                            value={userEmail}
                            onChange={() => handleCheckboxChange(userEmail)}
                        />
                        <label htmlFor={userEmail}>{userEmail}</label>
                    </div>
                ))}
                <button type="button" onClick={handleSubmit}>
                    Add Selected Users
                </button>
            </form>
        </div>
    );
}

export default AddUsersToGroup;