import React from 'react';
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './config/aws-exports';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AddUsersToGroup from './component/AddUsersToGroup';
import RemoveUsersFromGroup from './component/RemoveUsersFromGroup';
import UsersInGroupList from './component/UsersInGroupList';
import Home from './component/Home';

Amplify.configure({
  Auth: {
    Cognito: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID,
      loginWith: {
        email: true,
        name: true,
      }
    }
  }
});

const App = ({ signOut, user }) => {
  return (
    <div>
      <Heading level={3}>Admin: {user ? user.username : 'Loading...'}</Heading>
      <Button onClick={signOut}>Sign out</Button>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/add-users-to-group/:groupName" element={<AddUsersToGroup />} />
          <Route path="/remove-users-from-group/:groupName" element={<RemoveUsersFromGroup />} />
          <Route path="/list-users-in-group/:groupName" element={<UsersInGroupList />} />
        </Routes>
      </Router>
    </div>
  );
};

export default withAuthenticator(App);
