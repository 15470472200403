import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { fetchAuthSession } from 'aws-amplify/auth';
import awsExports from './config/aws-exports';

const httpLink = new HttpLink({
    uri: awsExports.APP_SYNCH_URL,
});

const authLink = setContext(async (_, { headers }) => {
    try {
        const session = await fetchAuthSession();
        const idToken = session.tokens.idToken;

        return {
            headers: {
                ...headers,
                authorization: idToken,
            },
        };
    } catch (error) {
        console.error('Error getting current session:', error);
        return {
            headers: {
                ...headers,
            },
        };
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default client;
