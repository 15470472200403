import React from 'react';
import UsersList from './UserList';
import GroupsList from './GroupList';

const Home = () => {
    return (
        <div>
            <h1>Dashboard</h1>
            <section>
                <UsersList />
            </section>
            <section>
                <GroupsList />
            </section>
        </div>
    );
};

export default Home;
