import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
    LIST_GROUPS
} from '../queries/gql';

function GroupsList() {
    const { loading: loading_groups, error: error_groups, data: data_groups } = useQuery(LIST_GROUPS);
    if (loading_groups) return <p>Loading...</p>;
    if (error_groups) {
        return (
            <p>
                Error :( {error_groups ? error_groups.message : ''}{' '}
            </p>
        );
    }

    return (
        <div>
            <h3>Groups:</h3>
            <ul>
                {data_groups?.listGroups?.map(({ groupName }) => (
                    <li key={groupName}>
                        {groupName} -
                        <Link to={`/add-users-to-group/${groupName}`}>Add Users</Link> -
                        <Link to={`/remove-users-from-group/${groupName}`}>Remove Users</Link> - 
                        <Link to={`/list-users-in-group/${groupName}`}>List Users</Link>
                    </li>
                ))}
            </ul>

        </div>
    );
}

export default GroupsList;
